import React from "react";
import arrow_top from '../images/arrow.png'

function ScrollTop() {
    return(
        <div>
            <div className='bg-purple animate-bounce w-12 h-12 float-right rounded-full mt-4 mr-10 hidden lg:block '>
                <button onClick={Top}>
                    <img className=' w-10 h-10 ml-1 mt-1' src={arrow_top} alt="scrollup icon" />
                </button>   
            </div> 
        </div>
    );
}
function Top() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    
}

export default ScrollTop ;