import React from "react";

export function validator(reqData) {
  let error = {};
  console.log("reqdata", reqData);
  if (reqData.isDrink) {
    const cocktailAmount = reqData.amount?.cocktail;
    const mocktailAmount = reqData.amount?.mocktail;

    if (cocktailAmount === undefined || cocktailAmount === null)
      error["cocktail"] = "please enter the amount";
    
    if (mocktailAmount === undefined || mocktailAmount === null)
      error["mocktail"] = "please enter the amount";
  }
  if (!reqData.isDrink) {
    const basicAmount = reqData.amount?.basic;
    if (basicAmount === undefined || basicAmount === null)
      error["basic"] = "please enter the amount";
  }

  if (reqData?.date === undefined || reqData?.date === null) {
    error["date"] = "please select the date";
  }
  return error;
}