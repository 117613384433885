import { React, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTop from "../Components/Scrollup";
import Card from "../Components/card";
import bold_type from "../images/the_bold_type.jpg";
import Layout from "../Components/layout";

function About() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Layout>
      <div>
        {/* First-section */}

        {/* <section>
                <div className=' mt-28 mb-10 mx-4 md:mx-8 lg:mx-36 2xl:mx-48 bg-yellow lg:flex '>
                    <div className='lg:w-2/4 border-2 border-yellow '>
                        <img className='w-82 h-72 md:w-10/12 md:h-fit lg:w-11/12 lg:h-11/12 xl:w-11/12 xl:h-11/12 -ml-6 -mt-8 md:-mt-14 lg:-mt-10 ' src='/Images/about-1.jpg' alt='about-1'/>
                    </div>
                    <div className='lg:w-2/4 font-[poppins] text-white py-4 px-4'>
                        <h2 className='text-2xl md:text-3xl lg:text-2xl font-semibold py-4'>HOW DID WE START?</h2>
                        <div className='text-base md:text-xl font-medium lg:text-lg tracking-wide  font-[raleway]'>
                            <p>
                            It all started with the Netflix show <span className='text-black font-semibold'>‘The Bold Type’.</span> While the founder of WNC, Kesica, was watching this show where 3 women in the same working environment had the privilege of sharing and learning from each other’s experiences. There was also this cool space exclusively for women to get together, vibe and maybe even attend workshops. She realised that no such support existed in India.
                            </p>
                            <p className='py-6 '>
                            Having come to the realisation that she has to be the change that she needs to be, she started this initiative with her team about whom you can read about below.
                            </p>
                        </div>
                    </div>

                </div>
            </section> */}

        {/* reference */}
        {/* 
                <div className='border-2 border-purple my-2 w-2/4 mx-auto'>hello width 500px</div>
                <div className='border-2 border-purple my-2  max-w-2xl mx-auto'>hello max- width 500px</div> */}

        <section>
          <div className="mx-4 md:mx-8 lg:mx-10">
            <div className=" mt-28 mb-10  bg-yellow lg:flex max-w-6xl mx-auto  ">
              <div className="lg:w-2/4 border-2 border-yellow ">
                <img
                  className="w-82 h-72 md:w-10/12 md:h-fit lg:w-11/12 lg:h-11/12  -ml-6 -mt-8 md:-mt-14  "
                  src={bold_type}
                  alt="about-1"
                />
              </div>
              <div className="lg:w-2/4 font-[poppins] text-white py-4 px-4">
                <h2 className="text-2xl md:text-3xl lg:text-2xl font-semibold py-4">
                  HOW DID WE START?
                </h2>
                <div className="text-base md:text-xl font-medium lg:text-lg tracking-wide  font-[raleway]">
                  <p>
                    It all started with the Netflix show{" "}
                    <span className="text-black font-semibold">
                      ‘The Bold Type’.
                    </span>{" "}
                    While the founder of WNC, Kesica, was watching this show
                    where 3 women in the same working environment had the
                    privilege of sharing and learning from each other’s
                    experiences. There was also this cool space exclusively for
                    women to get together, vibe and maybe even attend workshops.
                    She realised that no such support existed in India.
                  </p>
                  <p className="py-6 ">
                    Having come to the realisation that she has to be the change
                    that she needs to be, she started this initiative with her
                    team about whom you can read about below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Card />

        <ScrollTop />
      </div>
    </Layout>
  );
}

export default About;
