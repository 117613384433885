import React from "react";
import Illustration from "../images/Illustration.png";
import Line from "../images/Line 1.png";
import Line2 from "../images/Line 2.png";

function Milestone() {
  const heading =
    "text-[35px] sm::text-[40px] md:text-[60px] font-[poppins] font-semibold text-purple text";
  const Milestone =
    "text-black text-xl sm:text-[15px] md:text-2xl font-[poppins] font-light";
  const divider = "h-8 sm:h-12 w-0.5";
  return (
    <section className="mx-auto max-w-7xl px-5 py-4 sm:px-6 ">
      <div className="mx-auto max-w-3xl">
        <div className=" sm:h-[205px]">
          <h1 className="uppercase text-xl text-center lg:text-2xl mb-2">
            MILESTONES
          </h1>
          <div className="flex items-center justify-center mb-8">
            <img className="h-1 w-24" src={Line} />
          </div>

          <p className="mx-4 sm:text-center text-base font-medium font-[raleway] tracking-wide leading-7 lg:leading-7 mt-4 md:mt-2 sm:mt-[60px]">
            Lorem ipsum dolor sit amet consectetur.Varius cras risus vestibulum
            nequeurna in proin dignissim. Pellentesque turpis lorem elit hac
          </p>
        </div>
      </div>
      <div className="sm:flex mt-6 ">
        <img
          className="w-[336px] h-[301px] sm:w-[300px] sm:h-[250px] md:w-[500px] md:h-[400px] sm:block md:mr-6"
          src={Illustration}
          alt=""
        />

        <div className="sm:flex flex-col justify-center items-center my-12">
          <div>
          <div className="sm:flex sm:space-x-20 ">
            <div className="flex space-x-4 justify-center items-center">
              <h1 className={heading}>55</h1>
              <img className={divider} src={Line2} />
              <h1 className={Milestone}>Total Events</h1>
            </div>
            <div className="flex space-x-4 justify-center items-center mr-2">
              <h1 className={heading}>36</h1>
              <img className={divider} src={Line2} />
              <h1 className={Milestone}>Total Guest</h1>
            </div>
          </div>

          </div>
          <div className=" w-full">
          <div className="sm:flex sm:space-x-20">
            {/* <div className="flex space-x-4 justify-center items-center ml-1">
              <h1 className={heading}>37</h1>
              <img className={divider} src={Line2} />
              <h1 className={Milestone}></h1>
            </div> */}
            <div className="flex space-x-4 justify-center items-center mr-6 ">
              <h1 className={heading}>07 </h1>
              <img className={divider} src={Line2} />
              <h1 className={Milestone}>Total City</h1>
            </div>
          </div>

          </div>

          
        </div>
      </div>
    </section>
  );
}
export default Milestone;
