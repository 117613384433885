import AOS from "aos";
import "aos/dist/aos.css";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate, isCityDisabled } from "../Utils/functions";
import DrinkSelector from "./drinkSelector";
import { API_BASE_URL } from "../Utils/constants";
import CompanionModel from "../Pages/CompanionForm";
import CompanionForm from "../Pages/CompanionForm";
import { chennai4 } from "../Utils/image";
import { registerLocale } from "react-datepicker";

function Form() {
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    dateofbirth: "",
    carrier: "",
    city: "",
    work_experience: "",
    business_name: "",
    drink: "",
    companion_fname: "",
    companion_lname: "",
    companion_email: "",
    companion_phone: "",
    companion_dob: "",
    companion_carrier: "",
    companion_work_experience: "",
    companion_business_name: "",
  };

  const navigate = useNavigate();

  //State definitions
  const [registerDetails, setregisterDetails] = useState(initialValues);
  const [error, seterror] = useState({});
  const [form, setForm] = useState("");
  const [cityConfig, setCityConfig] = useState();
  const [show, setShow] = useState("hide");
  const [showSelectDrink, setShowSelectDrink] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [isCityVisible, setIsVisible] = useState(false);
  const [showCompanionForm, setCompanionForm] = useState(false);

  const getEventDetails = () => {
    fetch(`http://localhost:3000/api/v1/getEvent`)
      .then((res) => res.json())
      .then((response) => {
        console.log("----", response, "----");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function handleChange(e) {
    console.log("e", e.target);
    const { name, value } = e.target;

    if (name === "city") {
      console.log("city", value.id);
      console.log("cityConfig", cityConfig[value].date);
      setEventDate(cityConfig[value].date);
    }
    setregisterDetails({ ...registerDetails, [name]: value });
  }

  function handleOnClick(d) {
    if (!d) {
      setCompanionForm(true);
      if (Object.keys(validate(registerDetails)).length > 0) {
        seterror(validate(registerDetails));
        return;
      }
    }
    if (d) {
      if (Object.keys(validate(registerDetails, "companion")).length > 0) {
        seterror(validate(registerDetails, "companion"));
        return;
      }
      // setCompanionForm(true);
      handleSubmit();
    }
  }

  const createReg = (reqObj) => {
    const obj = { ...reqObj, event_date: eventDate };

    fetch(`${API_BASE_URL}/api/v1/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setregisterDetails(initialValues);
        setShowEvent(false);
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getCities = () => {
    fetch(`${API_BASE_URL}/getFirestoreEvent`)
      .then((res) => res.json())
      .then((response) => {
        console.log(response)
        setCityConfig(response);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

   console.log(cityConfig)
  
  // const getCities = () => {
  //   fetch(`${API_BASE_URL}/api/v1/readingjsondata`)
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log(response)
  //       setCityConfig(response);
  //     })
  //     .catch((err) => {
  //       console.error("error", err);
  //     });
  // };



  // const updateFilestoredata = () => {
  //   fetch(`${API_BASE_URL}/update`)
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setCityConfig(response);
  //     })
  //     .catch((err) => {
  //       console.error("error", err);
  //     });
  // };

  function handleSubmit(e) {
    if (Object.keys(validate(registerDetails)).length > 0) {
      console.log("error");

      seterror(validate(registerDetails));
      return;
    }

    let amount = null;
    if (cityConfig[registerDetails?.city].isDrink) {
      const selectedDrink = registerDetails?.drink;
      amount = cityConfig[registerDetails?.city].amount[selectedDrink];
    } else amount = cityConfig[registerDetails?.city].amount.basic;

    const rzpKey = "rzp_live_mIJgSBd1NGY26X";
    // const rzpKey = "rzp_test_N77BUviHwyHENt";

    // process.env.NODE_ENV === "development"
    //   ? "rzp_test_N77BUviHwyHENt"
    //   : "rzp_live_mIJgSBd1NGY26X";

    const options = {
      key: rzpKey,
      amount: amount, // 2000 paise = INR 20, amount in paisa
      currency: "INR",
      name: "Transaction Details",
      image:
        "https://womennetworkignclub.azurewebsites.net/static/images/wnc-logo.JPG",
      handler: function (response) {
        setregisterDetails((prevState) => ({
          ...prevState,
          razorpay_payment_id: response.razorpay_payment_id,
        }));

        createReg({
          ...registerDetails,
          rzp_id: response.razorpay_payment_id,
          rzp_amount: amount,
          payment_status: response.razorpay_payment_id ? true : false,
        });
      },
      prefill: {
        name: registerDetails?.first_name,
        email: registerDetails?.email,
      },
      notes: {
        address: "some address",
      },
      theme: {
        color: "#6a3669",
      },
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
  }

  useEffect(() => {
    getCities();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //Showing and Hiding Data and fields in a form.
  useEffect(() => {
    if (registerDetails.city === "") {
    }
    if (registerDetails.carrier === "Freelancer") {
      setShow("show");
      setForm(registerDetails.carrier);
    } else if (registerDetails.carrier === "Entreprenuer") {
      setShow("show");
      setForm(registerDetails.carrier);
    } else {
      setShow("hide");
    }

    if (cityConfig && cityConfig[registerDetails?.city]?.isDrink) {
      registerDetails?.drink !== "" && setShowEvent(true);
    } else {
      registerDetails?.city && setShowEvent(true);
    }
  }, [registerDetails]);

  console.log("cityConfig", cityConfig);

  //Animation Effect
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [showEvent]);

  function validate(values, form) {
    let err = {};
    console.log({ values });

    console.log(values.phone.length !== 10);
    const isCityHasDrink = cityConfig[values?.city]?.isDrink;

    if (!values.fname) {
      err.fname = "First name is required";
    }

    if (!values.lname) {
      err.lname = "Last name is required";
    }

    if (!values.email) {
      err.email = "Email ID is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      err.email = "Please enter a valid E-Mail ID";
    }

    if (!values.phone) {
      err.phone = "Phone number is required";
    } else if (values.phone.length !== 10) {
      err.phone = "Please enter a valid phone number";
    }

    if (!values.dateofbirth) {
      err.dateofbirth = "Date of birth is required";
    }

    if (!values.carrier) {
      err.carrier = "Please select an option";
    }

    if (!values.city) {
      err.city = "Please select an option";
    }

    if (!values.work_experience) {
      if (values.carrier === "Freelancer") {
        err.experience = "Please select an option";
      }
    }

    if (!values.business_name) {
      if (values.carrier === "Entreprenuer") {
        err.business_name = "Your business Name is required";
      }
    }

    if (isCityHasDrink && !values.drink) {
      err.drink = "Please choose your drink";
    }

    if (form === "companion") {
      if (!values.companion_fname) {
        err.companion_fname = "First name is required";
      }

      if (!values.companion_lname) {
        err.companion_lname = "Last name is required";
      }

      if (!values.companion_email) {
        err.companion_email = "Email ID is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.companion_email
        )
      ) {
        err.companion_email = "Please enter a valid E-Mail ID";
      }

      if (!values.companion_phone) {
        err.companion_phone = "Phone number is required";
      } else if (values.companion_phone.length !== 10) {
        err.companion_phone = "Please enter a valid phone number";
      }

      if (!values.companion_dob) {
        err.companion_dob = "Date of birth is required";
      }

      if (!values.companion_carrier) {
        err.companion_carrier = "Please select an option";
      }

      if (!values.companion_work_experience) {
        if (values.companion_companion_carrier === "Freelancer") {
          err.companion_companion_experience = "Please select an option";
        }
      }

      if (!values.companion_business_name) {
        if (values.companion_carrier === "Entreprenuer") {
          err.companion_business_name = "Your business Name is required";
        }
      }
    }
    console.log(err);

    return err;
  }

  console.log("registerDetails",registerDetails)

  return (
    <>
      <div className=" px-10 py-10 mx-auto bg-white w-full lg:w-2/3 md:block ">
        <h2 className="text-xl text-center sm:text-left">REGISTRATION</h2>
        {/* form container */}
        {!showCompanionForm ? (
          <div className="mx-4">
            <div className="flex-none text-sm mt-2 sm:flex sm:mt-4 ">
              <div className="mb-5 sm:mb-0">
                <h4>
                  First Name <span className="text-red">*</span>
                </h4>
                <input
                  className=" border-2 border-slate mt-2"
                  type="text"
                  placeholder="Roshini"
                  name="fname"
                  value={registerDetails.fname}
                  onChange={handleChange}
                />
                <br />
                <p className="text-red ml-1">{error.fname}</p>
              </div>

              <div className="mb-5 sm:mb-0 sm:ml-14">
                <h4>
                  Last Name <span className="text-red">*</span>
                </h4>
                <input
                  className="border-2 border-slate mt-2"
                  type="text"
                  placeholder="Alex"
                  name="lname"
                  value={registerDetails.lname}
                  onChange={handleChange}
                />
                <p className="text-red ml-1">{error.lname}</p>
              </div>
            </div>

            <div className="flex-none text-sm  sm:flex sm:py-3 ">
              <div className="mb-5 sm:mb-0">
                <h4>
                  Email <span className="text-red">*</span>
                </h4>
                <input
                  className="border-2 border-slate mt-2"
                  type="text"
                  placeholder="abc@gmail.com"
                  name="email"
                  value={registerDetails.email}
                  onChange={handleChange}
                />
                <p className="text-red ml-1">{error.email}</p>
              </div>
              <div className="mb-5 sm:mb-0 sm:ml-14">
                <h4>
                  WhatsApp Number <span className="text-red">*</span>
                </h4>
                <input
                  className="border-2 border-slate mt-2"
                  type="text"
                  placeholder="Please enter 10 digit number"
                  name="phone"
                  value={registerDetails.phone}
                  onChange={handleChange}
                />
                <p className="text-red ml-1">{error.phone}</p>
              </div>
            </div>

            <div className="flex-none text-sm sm:flex sm:py-3 ">
              <div className="mb-5 sm:mb-0">
                <h4>
                  Date of Birth <span className="text-red">*</span>
                </h4>
                <input
                  className="border-2 border-slate"
                  type="date"
                  placeholder=""
                  name="dateofbirth"
                  value={registerDetails.dateofbirth}
                  onChange={handleChange}
                />
                <p className="text-red ml-1">{error.dateofbirth}</p>
              </div>
              <div className="mb-5 sm:mb-0 sm:ml-14">
                <h4>
                  Are you? <span className="text-red">*</span>
                </h4>
                <select
                  className="dropdown-select"
                  name="carrier"
                  value={registerDetails.carrier}
                  onChange={handleChange}
                >
                  <option disabled selected value="">
                    Please Select
                  </option>
                  <option value="Entreprenuer">Entrepreneur</option>
                  <option value="Freelancer">Freelancer</option>
                  <option value="Aspiring Entrepreneur">
                    Aspiring Entrepreneur
                  </option>
                  <option>--None of the above --</option>
                </select>
                <p className="text-red ml-1">{error.carrier}</p>
              </div>
            </div>

            <div className="flex-none text-sm py-0  sm:flex sm:py-3">
              <div className={show === "show" ? "block" : "hidden"}>
                {form === "Freelancer" ? (
                  <div className="mb-5 sm:mb-0">
                    <h4>
                      Age of your business? <span className="text-red">*</span>
                    </h4>
                    <select
                      name="work_experience"
                      value={registerDetails.work_experience}
                      onChange={handleChange}
                      className="dropdown-select"
                    >
                      <option value="" selected disabled>
                        --Please Select--
                      </option>
                      <option value="0-2 years">0-2 years</option>
                      <option value="2-5 years">2-5 years</option>
                      <option value="5 years & above">5 years & above</option>
                      <option value="--None of the above--">
                        --None of the above--
                      </option>
                    </select>
                    <p className="text-red">{error.work_experience}</p>
                  </div>
                ) : (
                  <div className="mb-5 sm:mb-0">
                    <h4>
                      Brand/Business Name <span className="text-red">*</span>
                    </h4>
                    <input
                      type="text"
                      placeholder="Brand/Business"
                      name="business_name"
                      value={registerDetails.business_name}
                      onChange={handleChange}
                      required
                    />
                    <p className="text-red">{error.business_name}</p>
                  </div>
                )}
              </div>

              <div className={show === "show" ? "sm:ml-14" : null}>
                <h4>
                  City <span className="text-red">*</span>
                </h4>

                <select
                  className="dropdown-select"
                  name="city"
                  value={registerDetails.city}
                  onChange={handleChange}
                >
                  <option value={null} selected>
                    Please select city
                  </option>
                  {cityConfig &&
                    Object.keys(cityConfig).map((city) => {
                      return (
                        <option
                          key={city}
                          value={city}
                          disabled={!cityConfig[city].isCity}
                          className={`${
                            !cityConfig[city].isCity ? "text-gray-300" : null
                          }`}
                        >
                          {cityConfig[city].city}
                        </option>
                      );
                    })}
                </select>
                <p className="text-red ml-1">{error.city}</p>
              </div>
            </div>
            {registerDetails?.city &&
              cityConfig[registerDetails?.city]?.type === "offline" &&
              showSelectDrink && (
                <DrinkSelector
                  setregisterDetails={setregisterDetails}
                  registerDetails={registerDetails}
                  error={error}
                />
              )}
          </div>
        ) : (
          <CompanionForm
            errors={error}
            registerDetails={registerDetails}
            show={show}
            form={form}
            handleChange={handleChange}
          />
        )}

        {/* <p className={Object.keys(error).length === 0 ? 'text-sm text-fontgrey py-2' : 'text-sm text-red py-2'}><span className='text-red'>*</span> {Object.keys(error).length ===0 ?  "Fields are Mandatory" : "Please enter the mandatory fields"}</p> */}

        <div
          className={showEvent === true ? "block" : "hidden"}
          data-aos="fade-in"
        >
          <hr className="border-t-1 form-line border-slate my-5" />

          <h2 className="text-xl mt-3 text-center sm:text-left">
            EVENT DETAILS
          </h2>

          <div className="px-3 sm:grid grid-cols-2 gap-x-2 gap-y-5 my-5">
            <div className="flex items-center mb-3 sm:mb-0">
              <h4 className="text-md font-semibold">Date of the event :</h4>
              <p className="ml-3 text-md sm:text-sm">{eventDate}</p>
            </div>

            <div className="flex items-center mb-3 sm:mb-0">
              <h4 className="text-md font-semibold">Event City : </h4>
              <p className="ml-3 text-md capitalize sm:text-sm">
                {cityConfig && cityConfig[registerDetails.city]?.city}
              </p>
            </div>

            <div className="flex items-center mb-3 sm:mb-0">
              <h4 className="text-md font-semibold">Amount to be paid : </h4>
              <p className="ml-3 text-md sm:text-sm">
                ₹
                {cityConfig && cityConfig[registerDetails?.city]?.isDrink
                  ? cityConfig[registerDetails.city]?.amount[
                      registerDetails?.drink
                    ] / 100
                  : cityConfig &&
                    cityConfig[registerDetails?.city]?.amount?.basic / 100}
                {/* ₹ {PAYMENT_HANDLER[registerDetails.drink] / 100 || "0"} */}
              </p>
            </div>

            <div className="flex items-center mb-3 sm:mb-0">
              <h4 className="text-md font-semibold">Event Type : </h4>
              <p className="ml-3 text-md sm:text-sm">
                {cityConfig && cityConfig[registerDetails.city]?.type}
              </p>
            </div>
          </div>
        </div>

        <div className="hidden sm:block">
          {isCityVisible && (
            <h4 className="text-md mb-3">
              <span className="text-red">*</span>
              This event is for two people. Please fill the details of the
              companion in the next form.
            </h4>
          )}

          <h4 className="text-md mb-3">
            <span className="text-red">*</span> Please note that the
            registration fee is not refundable.
          </h4>
          {isCityVisible && (
            <button
              className="my-5 px-6 py-2 bg-purple text-white text-sm rounded"
              onClick={() => {
                handleOnClick(showCompanionForm);
              }}
            >
              {showCompanionForm ? "PAY" : "NEXT"}
            </button>
          )}
          {!isCityVisible && (
            <button
              className="my-5 px-6 py-2 bg-purple text-white text-sm rounded"
              onClick={handleSubmit}
            >
              PAY
            </button>
          )}

          <p className="text-sm tracking-wider">
            NOTE: If you are a woman entrepreneur and are interested in hosting
            these
            <br />
            meetings in your city, send us an email to{" "}
            <a href="mailto:info@womennetworkingclub.com">
              <span className="text-yellow underline">
                info@womennetworkingclub.com
              </span>
            </a>
            and
            <br />
            we’ll get in touch with you!
          </p>
        </div>
      </div>

      {/* Mobile footer */}
      <div className="block bg-white px-3 sm:hidden">
        {isCityVisible && (
          <h4 className="text-md mb-3">
            <span className="text-red">*</span>
            This event is for two people. Please fill the details of the
            companion in the next form.
          </h4>
        )}
        <h4 className="text-sm">
          <span className="text-red">*</span> Please not that the registration
          fee is not refundable
        </h4>
        {isCityVisible ? (
          <button
            className="my-5 px-6 py-2 bg-purple text-white text-sm rounded"
            onClick={() => {
              handleOnClick(showCompanionForm);
            }}
          >
            {showCompanionForm ? "PAY" : "NEXT"}
          </button>
        ) : (
          <button
            className="my-5 px-6 py-2 bg-purple text-white text-sm rounded"
            onClick={handleSubmit}
          >
            PAY
          </button>
        )}

        <p className="text-sm tracking-wider ">
          NOTE: If you are a woman entrepreneur and are interested in hosting
          these
          <br />
          meetings in your city, send us an email to
          <a href="mailto:info@womennetworkingclub.com">
            <span className="text-yellow underline">
              info@womennetworkingclub.com
            </span>
          </a>
          and
          <br />
          we’ll get in touch with you!
        </p>
      </div>
    </>
  );
}

export default Form;
