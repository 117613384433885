import React from "react";
import {Link} from 'react-router-dom'
import tick_icon from '../images/tick-icon.png'



function Thankyou () {

    return (
        <>
            <div className=" h-screen flex items-center justify-center bg-grey">
                <div className="shadow-lg p-10 flex flex-col items-center justify-center w-1/2 h-96 border-4 border-purple bg-white rounded-xl">
                    <img className="mb-6" src={tick_icon} alt="tick-icon" />
                    <h1 className="uppercase text-5xl mb-1 ">Thank you for registering!</h1>
                    <h4 className="text-2xl mt-3">We will be in your inbox soon :)</h4>
                    <Link to="/home">
                    <button className="px-5 py-3 rounded-xl mt-7 bg-purple text-white uppercase">Go to Home !</button>
                    </Link>
                </div>
            </div>
        </>
    )
};

export default Thankyou