import {
  kesica,
  soundaraya,
  cindana,
  akshitha,
  divya,
  alka_manoj,
  maryam,
  sakshi,
  pranathi,
  hastha,
  surabhi_bangalore,
  danielles,
} from "./image";


import {
  bangalore1,
  bangalore2,
  bangalore3,
  bangalore4,
  cbe1,
  cbe3,
  cbe2,
  cbe4,
  chennai1,
  chennai2,
  chennai3,
  chennai4,
  kolkata01,
  kolkata02,
  kolkata03,
  kolkata04,
  mangalore1,
  mangalore3,
  mangalore2,
  mangalore4,
  sweetsmit_cbe,
  mufasa_cbe,
  on_thengo_cbe,
  ektha_cbe,
  fabcafe_cbe,
  la_cafe_cbe,
  itc_cbe,
  zone_connect_cbe,
  loc_01_chennai,
  loc_02_chennai,
  loc_03_chennai,
  loc_04_chennai,
  bodhsara_banglr,
  dyu_banglr,
  cafe_reset_banglr,
  raahi_banglr,
  freshkitchen_manglr,
  h_manglr,
  vertex_manglr,
  whiteroom_manglr,
  la_macari,
  poach,
  saz,
  thetennistree,
  branch,
  cypress,
  feu,
  cravery



} from "./image";


export const DRINK_AMOUNT_HANDLER = [
  {
    drink: "Cocktail",
    slug: "cocktail",
    amount: "",
  },
  {
    drink: "Mocktail",
    slug: "mocktail",
    amount: "",
  },``
];

export const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://womennetworkingclub.com";

export const pastEvents = {
  COIMBATORE: {
    lead: {
      name: "KESICA JAYAPALAN",
      image: kesica,
      position: "LEAD & FOUNDER",
    },
    co_lead: {
      name: "SOUNDARYA GANESAN",
      image: soundaraya,
      position: "CO-LEAD",
    },
    guest: [
      {
        name: "Megha Asher",
        image: "",
        discription: "COO, Juicy Chemistry",
      },
      {
        name: "Shabhna",
        image: "",
        discription: "Zumba expert",
      },
     {
        name: "Rashmi Rajan Kapoor",
        image: "",
        discription: "MD of Tristar Accommodations Pvt Ltd",
      },
      {
        name: "Swati Jagdish",
        
        discription: "Influencer, Sex educator, Lactation consultant",
      },
      {
        name: "Ranjana Singhal",
        
        discription: "Founder of That's Y Food, Cafe Totaram, On the Go",
      },
      {
        name: "Abinaya Sundaram",
        
        discription: "Art Instructor",
      },
    ],
    location: [
      sweetsmit_cbe,
      mufasa_cbe,
      on_thengo_cbe,
      ektha_cbe,
      // "fabcafe_cbe",
      // "la_cafe_cbe",
      // "itc_cbe",
      // "zone_connect_cbe"
    ],

    images: [cbe2],
  },
  CHENNAI: {
    lead: {
      name: "DIVYA MAHADEVAN",
      image: divya,
      position: "LEAD & FOUNDING MEMBER",
    },
    co_lead: {
      name: "CINDANA MANICKAVEL",
      image: cindana,
      position: "CO-LEAD",
    },
    guest: [
      {
        name: "Dr. Rohini Rau",

        discription: "Former Sailor",
      },
      {
        name: "Pallavi Shah",

        discription: "Social Media Consultant & Coach",
      },
      {
        name: "Paloma Rao",

        discription: "TV Host & RJ",
      },
      {
        name: "Natasha Goenka",

        discription: "Certified Image Consultant",
      },
    ],
    location: [
      loc_01_chennai,
      loc_02_chennai,
      loc_03_chennai,
      loc_04_chennai],

    images: [chennai1]
  },
  KOLKATA: {
    lead: {
      name: "DANIELLE JONES",
      image: danielles,
      position: "LEAD & FOUNDING MEMBER",
    },
    co_lead: { name: "", image: "", position: "" },
    guest: [
      {
        name: "Kounteya Sinha",
        image: "",
        discription: "Public Speaker & Author",
      },
     
       {
        name: "Chef Jaytri",
        image: "",
        discription: "Chef & Founder of 'The Fat Little Penguin'",
      },
      {
        name: "Lahana Gosh",
       
        discription: "Entrepreneur",
      },
      {
        name: "Ankita Gupta Malik",
       
        discription: "Lactation specialist and prenatal yoga instructor",
      },
      {
        name: "Shivika Burman",
       
        discription: "Tennis Coach",
      },

    ],
    location: [la_macari,
      poach,
      saz,
      thetennistree],
    images: [kolkata01]
  },
  BANGALORE: {
    lead: {
      name: "AKSHITHA BASAVARAJU",
      image: akshitha,
      position: "LEAD & FOUNDING MEMBER",
    },
    co_lead: {
      name: "SURABHI SHANDILYA",
      image: surabhi_bangalore,
      position: "CO-LEAD",
    },
    guest: [
      {
        name: "Michelle Salins",
       
        discription: "Fashion Designer & Entrepreneur",
      },
      {
        name: "Monika Manchanda",
        
        discription: "Co-founder of LiveAltlife",
      },
      {
        name: "Meghana Manay",
        
        discription: " Entrepreneur",
      },
      {
        name: "Mita Vinay",
        
        discription: " Yoga expert, Mindfulness Coach",
      },
    ],
    location: [
      bodhsara_banglr,
      dyu_banglr,
      cafe_reset_banglr,
      raahi_banglr
    ],
    images: [bangalore1]
  },
  HYDERABAD: {
    lead: {
      name: "PRANATHI NANDAMURAI",
      image: pranathi,
      position: "LEAD",
    },
    co_lead: {
      name: "MARYAM QUADRI",
      image: maryam,
      position: "CO-LEAD ",
    },
    guest: [
      {
        name: "Samaira Wallani",
        
        discription: "Actor & Influencer",
      },
      {
        name: "Amulya Gullapalli",
        
        discription: "Architects",
      },
      {
        name: "Prashanthi Narapasetty",
        
        discription: "Architects",
      },
    ],
    location: [
      branch,
      cypress,
    ],images :null
  },
  DELHI: {
    lead: {
      name: "SAKSHI KAUL WATTAL",
      image: sakshi,
      position: "LEAD",
    },
    co_lead: { name: "", image: "", position: "" },
    guest: [
    
      {
        name: "Neha Sahu",
        
        discription: "Artist & Contemporary Designer",
      },
      
      
      {
        name: "Anika Parashar",
        
        discription: "Founder of The Woman's Company",
      },

      {
        name: "Sanjali Nirwani",
        
        discription: "CEO of Unloacked India",
      },
      

    ],
    location: [
      feu,
      cravery
    ],
    images:null
  },
  MANGALORE: {
    lead: {
      name: "ALKA MANOJ",
      image: alka_manoj,
      position: "LEAD",
    },
    co_lead: {
      name: "HASTHA NARAYAN",
      image: hastha,
      position: "CO-LEAD",
    },
    guest: [
      {
        name: "Sapna Shenoy",
        image: "",
        discription: "Investment Professional",
      },
      {
        name: "Sapna Naronha",
        image: "",
        discription: "Artist, Art Curator",
      },
      {
        name: "Deepa Rao",
        image: "",
        discription: "Stylist, Fashion Analyst, Designer",
      },
      
    ],
    location: [
      freshkitchen_manglr,
      h_manglr,
      vertex_manglr,
      whiteroom_manglr
    ],
    images: [mangalore1]
  },
};

// export const pastEvents = {
//   2024: {
//     January: [
//       {
//         cityName: "Coimbatore",
//         lead: "KESICA JAYAPALAN",
//         co_lead: "SOUNDARYA GANESAN",
//         image: Jan24Cbe01
//       },
//     ],

//     February: [
//       {
//         cityName: "Coimbatore",
//         lead: "KESICA JAYAPALAN",
//         co_lead: "SOUNDARYA GANESAN",
//       },
//       {
//         cityName: "Mangalore",
//         lead: "ALKA MANOJ",
//         co_lead: "HASTHA NARAYAN",
//       },
//     ],
//     March: [
//       {
//         cityName: "Coimbatore",
//         lead: "KESICA JAYAPALAN",
//         co_lead: "SOUNDARYA GANESAN",
//       },
//       {
//         cityName: "Mangalore",
//         lead: "ALKA MANOJ",
//         co_lead: "HASTHA NARAYAN",
//       },
//       {
//         cityName: "Chennai",
//         lead: "DIVYA MAHADEVAN",
//         co_lead: "CINDANA MANICKAVEL",
//       },
//     ],
//   },
// };
