import React from "react";
import ScrollTop from "../Components/Scrollup";
import Layout from "../Components/layout";

function Terms() {
  return (
    <Layout>
      <div>
        <section>
          <div className="bg-grey px-6 py-14 lg:px-20">
            <div className="bg-white px-6 py-10 lg:px-12">
              <h2 className="text-2xl pb-2 font-[poppins]">
                Terms and Conditions
              </h2>

              <h3 className="py-6 font-[poppins] text-base">
                General Conditions
              </h3>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                We reserve the right to refuse service to anyone for any reason
                at any time. You understand that your content (not including
                credit card information), may be transferred unencrypted and
                involve (a) transmissions over various networks; and (b) changes
                to conform and adapt to technical requirements of connecting
                networks or devices. Credit card information is always encrypted
                during transfer over networks. You agree not to reproduce,
                duplicate, copy, sell, resell or exploit any portion of the
                Service, use of the Service, or access to the Service or any
                contact on the website through which the service is provided,
                without express written permission by us. The headings used in
                this agreement are included for convenience only and will not
                limit or otherwise affect these Terms.
              </p>

              <h3 className="pt-6 pb-4 font-[poppins] text-base">
                Modification of the Service and Price
              </h3>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                Prices for our products are subject to change without notice. We
                reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice at any
                time. We shall not be liable to you or to any third-party for
                any modification, price change, suspension or discontinuance of
                the Service.
              </p>

              <h3 className="pt-6 pb-4 font-[poppins] text-base">
                User Comments, Feedback and Other Submissions
              </h3>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                If, at our request, you send certain specific submissions (for
                example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other
                materials, whether online, by email, by postal mail, or
                otherwise (collectively, 'comments'), you agree that we may, at
                any time, without restriction, edit, copy, publish, distribute,
                translate and otherwise use in any medium any comments that you
                forward to us. We are and shall be under no obligation (1) to
                maintain any comments in confidence; (2) to pay compensation for
                any comments; or (3) to respond to any comments.
              </p>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5 py-5">
                We may, but have no obligation to, monitor, edit or remove
                content that we determine in our sole discretion are unlawful,
                offensive, threatening, libelous, defamatory, pornographic,
                obscene or otherwise objectionable or violates any party’s
                intellectual property or these Terms of Service.
              </p>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                You agree that your comments will not violate any right of any
                third-party, including copyright, trademark, privacy,
                personality or other personal or proprietary right. You further
                agree that your comments will not contain libelous or otherwise
                unlawful, abusive or obscene material, or contain any computer
                virus or other malware that could in any way affect the
                operation of the Service or any related website. You may not use
                a false e-mail address, pretend to be someone other than
                yourself, or otherwise mislead us or third-parties as to the
                origin of any comments. You are solely responsible for any
                comments you make and their accuracy. We take no responsibility
                and assume no liability for any comments posted by you or any
                third-party
              </p>

              <h3 className="pt-6 pb-4 font-[poppins] text-base">Pricing</h3>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5 ml-4 py-1">
                - Offline Meetings -{" "}
                <span className="font-semibold"> Rs. 799/- </span>
              </p>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5 ml-4 py-1">
                - Online Meetings -{" "}
                <span className="font-semibold"> Rs. 299/- </span>
              </p>

              <h2 className="text-2xl py-4 mt-2 font-[poppins]">
                Refund Policy
              </h2>
              <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                We do not provide refunds as we close registrations once we
                reach the planned number. The fee cannot be transferred to
                another month. In case you would like to send someone else in
                your place, you can do so provided you have informed us ahead of
                time.{" "}
              </p>

              <div>
                <h2 className="text-2xl py-2 mt-4 font-[poppins]">
                  Privacy Policy
                </h2>
                <h3 className="pt-6 pb-4 font-[poppins] text-base">
                  Privacy Policy Summary
                </h3>
                <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                  We will never spam you. Safeguarding your information is our
                  top priority. We will never sell, distribute or bow to
                  pressure from any associations and business seeking your
                  information. Whatever you post on the site and make it public
                  is for public consumption.
                </p>
                <h3 className="pt-6 pb-4 font-[poppins] text-base">
                  This policy applies to:
                </h3>
                <p className="terms_content font-[Raleway] text-xs md:text-sm md:tracking-wider md:leading-6 tracking-wide text-fontgrey leading-5">
                  Some parts of this policy are directed at specific types of
                  people and we explain the terms we use to refer to them below.
                  The policy in general applies to everybody using our website
                  and services, but sometimes parts of it are directed to these
                  particular group of users.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>

        <ScrollTop />
      </div>
    </Layout>
  );
}

export default Terms;
