import React, { useEffect, useState } from "react";
import { getStatus } from "../Utils/functions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ActiveStatus({ date, isCity }) {
  const [status, setStatus] = useState(" ");

  useEffect(() => {
    const eventStatus = getStatus(date);
    console.log("eventStatus",eventStatus)
    if (isCity) {
      if (eventStatus) setStatus("Registration Open");
      else setStatus("Registration Closed");
    } else {
      setStatus("No Event");
    }
  }, [date]);
  return (
    <div className="mx-3 z-10">
      <span
        className={classNames(
          "inline-flex items-center rounded-full  px-4 py-1 text-xs font-medium  m-2 z-30",
          !isCity && "bg-yellow text-white ",
          getStatus(date)
            ? " bg-green text-greenone "
            : "bg-yellow text-white "
        )}
      >
        {status}
      </span>
    </div>
  );
}