import React from "react";

export default function CompanionForm({
  errors,
  registerDetails,
  show,
  form,
  handleChange,
}) {
  console.log("registerDetails", registerDetails.companion_name);
  console.log("errors", errors);
  return (
    <div>
      {/* form container */}
      <div className="mx-4">
        <div className="flex-none text-sm mt-2 sm:flex sm:mt-4 ">
          <div className="mb-5 sm:mb-0">
            <h4>
              Companion First Name <span className="text-red">*</span>
            </h4>
            <input
              className=" border-2 border-slate mt-2"
              type="text"
              placeholder="Roshini"
              name="companion_fname"
              value={registerDetails.companion_name}
              onChange={handleChange}
            />
            <br />
            <p className="text-red ml-1">{errors.companion_fname}</p>
          </div>

          <div className="mb-5 sm:mb-0 sm:ml-14">
            <h4>
              Last Name <span className="text-red">*</span>
            </h4>
            <input
              className="border-2 border-slate mt-2"
              type="text"
              name="companion_lname"
              value={registerDetails.companion_lname}
              onChange={handleChange}
            />
            <p className="text-red ml-1">{errors.companion_lname}</p>
          </div>
        </div>

        <div className="flex-none text-sm  sm:flex sm:py-3 ">
          <div className="mb-5 sm:mb-0">
            <h4>
              Email <span className="text-red">*</span>
            </h4>
            <input
              className="border-2 border-slate mt-2"
              type="text"
              placeholder="abc@gmail.com"
              name="companion_email"
              value={registerDetails.companion_email}
              onChange={handleChange}
            />
            <p className="text-red ml-1">{errors.companion_email}</p>
          </div>
          <div className="mb-5 sm:mb-0 sm:ml-14">
            <h4>
              WhatsApp Number <span className="text-red">*</span>
            </h4>
            <input
              className="border-2 border-slate mt-2"
              type="text"
              placeholder="Please enter 10 digit number"
              name="companion_phone"
              value={registerDetails.companion_phone}
              onChange={handleChange}
            />
            <p className="text-red ml-1">{errors.companion_phone}</p>
          </div>
        </div>

        <div className="flex-none text-sm sm:flex sm:py-3 ">
          <div className="mb-5 sm:mb-0">
            <h4>
              Date of Birth <span className="text-red">*</span>
            </h4>
            <input
              className="border-2 border-slate"
              type="date"
              placeholder=""
              name="companion_dob"
              value={registerDetails.companion_dob}
              onChange={handleChange}
            />
            <p className="text-red ml-1">{errors.companion_dob}</p>
          </div>
          <div className="mb-5 sm:mb-0 sm:ml-14">
            <h4>
              Are you? <span className="text-red">*</span>
            </h4>
            <select
              className="dropdown-select"
              name="companion_carrier"
              value={registerDetails.companion_carrier}
              onChange={handleChange}
            >
              <option disabled selected value="">
                Please Select
              </option>
              <option value="Entreprenuer">Entrepreneur</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Aspiring Entrepreneur">
                Aspiring Entrepreneur
              </option>
              <option>--None of the above --</option>
            </select>
            <p className="text-red ml-1">{errors.companion_carrier}</p>
          </div>
        </div>

        <div className="flex-none text-sm py-0  sm:flex sm:py-3">
          <div className={show === "show" ? "block" : "hidden"}>
            {registerDetails.companion_carrier === "Freelancer" ? (
              <div className="mb-5 sm:mb-0">
                <h4>
                  Age of your business? <span className="text-red">*</span>
                </h4>
                <select
                  name="companion_work_experience"
                  value={registerDetails.companion_work_experience}
                  onChange={handleChange}
                  className="dropdown-select"
                >
                  <option value="" selected disabled>
                    --Please Select--
                  </option>
                  <option value="0-2 years">0-2 years</option>
                  <option value="2-5 years">2-5 years</option>
                  <option value="5 years & above">5 years & above</option>
                  <option value="--None of the above--">
                    --None of the above--
                  </option>
                </select>
                <p className="text-red ml-1">
                  {errors.companion_work_experience}
                </p>
              </div>
            ) : (
              <div className="mb-5 sm:mb-0">
                <h4>
                  Brand/Business Name <span className="text-red">*</span>
                </h4>
                <input
                  type="text"
                  placeholder="Brand/Business"
                  name="companion_business_name"
                  value={registerDetails.companion_business_name}
                  onChange={handleChange}
                  required
                />
                <p className="text-red ml-1">
                  {errors.companion_business_name}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
