import {React, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import kesica from '../images/Founders/kesica.jpg';
import soundaraya from '../images/Founders/soundaraya.jpg';
import alka_manoj from '../images/Founders/alka_manoj.jpeg';
import akshitha from '../images/Founders/akshitha.jpeg';
import surabhi_bangalore from '../images/Founders/surabhi_bangalore.jpg';
import hastha from '../images/Founders/hastha.jpg';
import cindana from '../images/Founders/cindana.jpg';
import danielles from '../images/Founders/danielles.jpeg';
import divya from '../images/Founders/divya.jpg';
import maryam from '../images/Founders/maryam.jpg';
import not_found from '../images/Founders/not_found.svg';
import pranathi from '../images/Founders/pranathi.jpg';
import sakshi from '../images/Founders/sakshi.jpg';
function Card (){
     useEffect(()=>{
        AOS.init({duration:2000});
    }, []);
     return(
        <div>
            <section className='bg-grey pt-8 pb-20 lg:pt-0 lg:px-20'>
                    <div className=''>
                        <div className='pt-10 pb-10'>
                            <h1 className='uppercase text-3xl text-center font-semibold'>about us</h1>
                            <hr className='border-t-2 border-purple w-14 my-2 mx-auto' />
                        </div> 
                        <div className='max-w-7xl mx-auto lg:py-8'>

                            {/* first section */}
                            <div className="flex flex-col lg:flex-row lg:my-24" >
                                {/* Host */}``
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 lg:gap-8 order-last lg:order-first " >
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow' data-aos="fade-right" data-aos-duration="1000">
                                        <div className="flex justify-evenly ">
                                            <img className='w-40 h-40 rounded-sm ml-2 -mt-12 ' src={kesica} alt='kesica' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl'>KESICA JAYAPALAN</h2>
                                                <h2 className='my-2 font-medium text-xs  lg:text-sm'>Founder &nbsp; & <br />Lead - Coimbatore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md '>
                                          <p>An avid traveler, Kesica is the founder of the casual saree brand ‘Seven Yards’ and also heads Business Development at Lands & Lands, a real estate firm based out of Coimbatore. She aspires to be a serial entrepreneur and hopes to uplift women through her ventures. In the meanwhile, she is also well underway to visiting every single country on the planet.</p>
                                          <p className="py-6">When she’s not busy being a workaholic boss lady, Kesica loves spending time with her pack of dogs and her wolf-dog. She also enjoys reading and the occasional game of tennis having played it professionally as well as at the US collegiate level. Kesica has an unhealthy obsession with the Number ‘7’.</p>
                                        </div>
                                    </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg mx-auto bg-white mt-20 lg:mt-0 border-2 border-yellow' data-aos="fade-right"  data-aos-duration="1000">
                                        <div className="flex justify-evenly">
                                            <img className='w-44 h-40 ml-2 rounded-sm -mt-12' src={soundaraya} alt='Soundarya' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl uppercase '>Soundarya Ganesan </h2>
                                                <h2 className='my-2 font-medium text-sm '>Co-Lead - Coimbatore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>Meet Soundarya Ganesan our Co-Lead for the Coimbatore Chapter.
                                            She’s a BCom graduate, Delhi and a CA finalist. She is the founder of Vastara - an affordable everyday wear brand. </p>
                                            <p className="py-6">
                                            If you’re someone who has a lot of ideas to discuss, she’s the one you’d want to go on a coffee date with to discuss the opportunities and assess the feasibility. She’s a jack of all trades.
                                            She’s a fitness enthusiast and loves playing badminton.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto'data-aos="fade-left" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl lg:rotate-90 '>COIMBATORE</h1>
                                </div>
                            </div>

                            {/*Chennai */}
                            <div className="lg:flex my-20 lg:my-40">
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto' data-aos="fade-right" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-yellow text-4xl lg:text-5xl lg:-rotate-90'>CHENNAI</h1>
                                </div>

                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8" data-aos="fade-left" data-aos-duration="1000">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow '>
                                        <div className="flex justify-evenly">
                                            <img className='w-40 h-40 ml-2 rounded-sm -mt-12' src={divya} alt='divya' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl'>DIVYA MAHADEVAN</h2>
                                                <h2 className='my-2 font-medium text-sm '>Founding Member & <br />Lead - Chennai</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>
                                            With three full time jobs, Divya is very busy being a mom to her 2-year-old, a homebaker and the founder of a <span className='font-semibold'>moms support group!</span> Her passion has always been to bake and she recently converted into her profession as well to get the best of both worlds. Her vision is to build a country-wide moms support group, so that pregnant moms from all over the country can go through the lovely and challenging journey of motherhood together.
                                            </p>
                                            <p className='py-4'>
                                            Food just can’t stay from Divya, when she’s not baking, she’s not surprisingly, ‘cooking’ and feeding people. As an escape from her hectic schedule, she loves to grab a good dessert with a side of black coffee or enjoy a day at the spa.
                                            </p>                                        
                                        </div>
                                    </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg bg-white mx-auto border-2 border-yellow mt-20 lg:mt-0' data-aos="fade-left" data-aos-duration="1000">
                                        <div className="flex justify-evenly">
                                            <img className='w-44 h-40 ml-2 rounded-sm -mt-12' src={cindana} alt='Cindana' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">  
                                                <h2 className='text-xl uppercase'>Cindana Manickavel</h2>
                                                <h2 className='my-2 font-medium text-sm'>Co-Lead - Chennai</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                          <p>
                                          Meet Cindana Manickavel, our Co-Lead for Chennai WNC Chapter. She is a perfect example of the quote "A successful business needs one simple thing - Passion." A self-taught baker, she relentlessly pursued her passion for baking with constant learning and practice. She now has a super successful business @mysugarmonkeys and is giving her customers delectable cakes after cakes.
                                          </p>
                                          <p className="py-4">
                                            She is also an Event Manager and Fitness Enthusiast. Her passions are fitness and food. She envisions a future where she can balance personal and professional lives and create two unique brands. Apart from baking yummy goodies, she loves to watch movies, go on long drives, shop and do workouts.
                                          </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Kolkata */}

                            <div className="flex flex-col lg:flex-row lg:my-24">
                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8 order-last lg:order-first" data-aos="fade-right" data-aos-duration="1000">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow '>
                                        <div className="flex justify-evenly">
                                            <img className='w-2/6 h-2/6 ml-2 rounded-sm -mt-12' src={danielles}  alt='danielles' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl'>DANIELLE JONES</h2>
                                                <h2 className='my-2 font-medium text-sm'>Founding Member & <br/> Lead - Kolkata</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p> 
                                            The Director of <span className='font-semibold'>Bridgette Jones fashion salon </span> (has nothing to do with the diary), Danielle Jones is a professional makeup artist and an integrative nutrition health coach. Her passions are ever changing and her motto is onwards and upwards where the great energy flows. She hopes her salons mushroom up all over India one day while simultaneously supporting other women through WNC.
                                            </p>
                                            <p className='py-6'> 
                                            Boredom is defeated by reading, walking on scenic routes, learning new things and petting animals along the way (yes, all of them). Danielle is the only one who refused to share her quirky trait with the group, we wonder what she’s hiding hmmm.
                                            </p>                                        
                                            </div>
                                        </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow mt-20 lg:mt-0' data-aos="fade-right" data-aos-duration="1000">
                                        {/* <div className="flex justify-evenly">
                                            <img className='w-2/6 h-2/6 ml-2 rounded-sm -mt-12' src='/Images/404_page.svg' alt='404' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl uppercase'>NOT available</h2>
                                                <h2 className='my-2 font-medium text-sm '>Co-Lead - Kolkata</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                          <p>An avid traveler, Kesica is the founder of the casual saree brand ‘Seven Yards’ and also heads Business Development at Lands & Lands, a real estate firm based out of Coimbatore. She aspires to be a serial entrepreneur and hopes to uplift women through her ventures. In the meanwhile, she is also well underway to visiting every single country on the planet.</p>
                                          <p className="py-6">When she’s not busy being a workaholic boss lady, Kesica loves spending time with her pack of dogs and her wolf-dog. She also enjoys reading and the occasional game of tennis having played it professionally as well as at the US collegiate level. Kesica has an unhealthy obsession with the Number ‘7’.</p>
                                        </div> */}
                                        <img className='w-50 px-12 py-16' src={not_found} alt=''/>
                                    </div>
                                </div>
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto' data-aos="fade-left" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl lg:rotate-90'>KOLKATA</h1>
                                </div>
                            </div>


                            {/* Bangalore */}
                            <div className="lg:flex my-20 lg:my-40">
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto'data-aos="fade-right" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-yellow text-4xl lg:text-5xl lg:-rotate-90 '>BANGALORE</h1>
                                </div>

                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8" data-aos="fade-left" data-aos-duration="1000">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow'>
                                        <div className="flex justify-evenly">
                                            <img className='w-40 h-40 ml-2 rounded-sm -mt-12' src={akshitha} alt='akshitha' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl'>AKSHITHA BASAVARAJU</h2>
                                                <h2 className='my-2 font-medium text-sm'>Founding Member & <br/> Lead - Bangalore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>An educationist and <span className='font-semibold'> internationally certified womens’ fitness specialist </span> by profession, Akshita has a full time passion of spreading her love for fitness with everyone. She believes that a fit mom is the key to moulding a healthy home and hopes to transform lives through her fitness movement - emotionally, physically and socially </p>
                                            <p className='py-6'>In her free time, Akshitha loves to go for a mindful run, read up on everything education and fitness while also staying in the loop on social media. This mom has a very unique special ability: Cartwheeling. She hopes to make her signature move a very common sight in Indian households..</p>                                        </div>
                                        </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow  mt-20 lg:mt-0' data-aos="fade-left" data-aos-duration="1000">
                                        <div className="flex justify-evenly">
                                            <img className='w-44 h-40 ml-2 rounded-sm -mt-12' src={surabhi_bangalore} alt='Surabhi' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl uppercase'>Surabhi Shandilya</h2>
                                                <h2 className='my-2 font-medium text-sm'>Co-Lead - Bangalore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>Meet Surabhi Shandilya, our Co-Lead for Bengaluru WNC Chapter. This powerhouse of a woman is a journalist turned entrepreneur! With a decade of experience in Journalism, Marketing and Public Relations, she founded Pitch Perfect, an integrated marketing firm that offers public relations, social media marketing, branding and media management.</p>
                                            <p className="py-6">She has spearheaded award-winning campaigns for brands like Dell, NDTV Good Times, Kingfisher, Airbnb, UNICEF, Xiaomi, Myntra and Flipkart to name a few. Her research paper on child labour won a national award from UNICEF and the Government of India. She comes with an in-depth understanding of the media landscape in India.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                            {/* Hyderabad */}

                            <div className="flex flex-col lg:flex-row mt-16 lg:mt-40" data-aos="fade-right" data-aos-duration="1000">
                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8 order-last lg:order-first">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow'>
                                        <div className="flex justify-evenly">
                                            <img className='w-40 h-40 ml-2 rounded-sm -mt-12' src={pranathi} alt='pranathi' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl '>PRANATHI NANDAMURAI</h2>
                                                <h2 className='my-2 font-medium text-sm'>Lead - Hyderabad</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p> 
                                                Pranathi Nandamuri is our host for WNC sessions in Hyderabad. Fuelled by an undying passion for building a series of businesses led and run entirely by women, she established her company Athena Advertising as the first in the series. @athenaadvertising is a creative branding, advertising and digital marketing company located in Hyderabad. Her vision for the future is to keep moving forward by working on different projects related to women's empowerment and working for brands that are here to make a difference.
                                            </p>
                                            <p className='py-6'> 
                                                Tennis is her first love and she has played at the national level. Apart from playing tennis, she indulges in reading, spending time with her dog Milo and travelling. She is a self-confessed stationery hoarder and particularly hoards on handmade notebooks that she seldom writes in.
                                            </p>                                     
                                        </div>
                                        </div>
                                    {/* co-host */}
                                    <div className='max-w-lg bg-white mx-auto border-2 border-yellow mt-20 lg:mt-0' data-aos="fade-right" data-aos-duration="1000">
                                        <div className="flex justify-evenly">
                                            <img className='w-40 h-40 ml-2 rounded-sm -mt-12' src={maryam} alt='Maryam' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl uppercase'>Maryam Quadri</h2>
                                                <h2 className='my-2 font-medium text-sm '>Co-Lead - Hyderabad</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>Meet Maryam Quadri, our Co-Lead for Hyderabad WNC sessions. Maryam is a powerhouse of a woman, dividing her time and energy into multiple channels. She is the co-founder of a luxury men's accessories and jewellery brand, @mirthelabel, is the Content and Creations Head at a Social Media Agency, @athena.advertising and the Senior Coordinator for a women's magazine, @tangmagazine for which she has interviewed over a 100 women during the pandemic and continues to do so, gaining inspiration and celebrating their successes. </p>
                                            <p className="py-4">Maryam believes in the importance of equal opportunities and acknowledgement for women. Racism, colourism, body positivity and in general, kindness towards women, are topics she is most passionate about and writes about them in published anthologies and works. She holds an MBA in HR Marketing, is a Psychology Graduate, has been an amateur drag racer, a former makeup artist and is working towards starting her entrepreneurial journey.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto' data-aos="fade-left" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl lg:rotate-90'>HYDERABAD</h1>
                                </div>
                            </div>

                            {/* Delhi */}

                            <div className="lg:flex my-20 lg:my-40">
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto' data-aos="fade-right" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-yellow text-4xl lg:text-5xl lg:-rotate-90'>DELHI</h1>
                                </div>

                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8" data-aos="fade-left" data-aos-duration="1000">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow'>
                                        <div className="flex justify-evenly">
                                            <img className='w-40 h-40 ml-2 rounded-sm -mt-12' src={sakshi}  alt='sakshi' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl '>SAKSHI KAUL WATTAL</h2>
                                                <h2 className='my-2 font-medium text-sm'>Lead - Delhi NCR</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>
                                            Sakshi Kaul Wattal is our host for Delhi NCR and will conduct the sessions starting this month. A strong advocate of sustainable living, she is passionate about finding sustainable alternatives to existing products that harm the environment. Apart from the time she spends with her daughter, most of her time is dedicated to creating and testing prototypes for her business. She is the co-founder of Rangvarukh, a company that sells plastic-free organisers. She dreams to make #organisedlife a movement to help people make conscious and sustainable choices with respect to the things they buy.
                                            </p>
                                            <p className='py-4'>
                                            When she's not building her company or doting on her daughter, Sakshi can be seen gardening, swimming or reading. Her favourite genres in books are Spirituality & Psychology.
                                            </p>                                      
                                        </div>
                                    </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow mt-20 lg:mt-0' data-aos="fade-left" data-aos-duration="1000">
                                        {/* <div className="flex justify-evenly">
                                            <img className='w-44 h-40 ml-2 rounded-sm -mt-12' src='/Images/404_page.svg' alt='404' />
                                            <div className="font-semibold font-[poppins] my-auto text-center">
                                                <h2 className='text-xl uppercase'>Not available</h2>
                                                <h2 className='my-2 font-medium text-sm'>Co-Lead - Chennai</h2>
                                            </div>
                                        </div> */}
                                        {/* <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                          <p>
                                          Meet Cindana Manickavel, our Co-Lead for Chennai WNC Chapter. She is a perfect example of the quote "A successful business needs one simple thing - Passion." A self-taught baker, she relentlessly pursued her passion for baking with constant learning and practice. She now has a super successful business @mysugarmonkeys and is giving her customers delectable cakes after cakes.
                                          </p>
                                          <p className="py-4">
                                            She is also an Event Manager and Fitness Enthusiast. Her passions are fitness and food. She envisions a future where she can balance personal and professional lives and create two unique brands. Apart from baking yummy goodies, she loves to watch movies, go on long drives, shop and do workouts.
                                          </p>
                                        </div> */}
                                        <img className='w-50 px-12 py-16' src={not_found} alt=''/>

                                    </div>
                                </div>
                            </div>

                            {/* Mangalore */}
                            <div className=" flex flex-col lg:flex-row my-20 lg:my-40">
                                {/* Host */}
                                <div className="w-5/6 mx-auto lg:grid grid-cols-2 gap-8 order-last lg:order-first" data-aos="fade-right" data-aos-duration="1000">
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow '>
                                        <div className="flex justify-evenly">
                                            <img className='w-2/6 h-2/6 ml-2 rounded-sm -mt-12' src={alka_manoj} alt='alkamanoj' />
                                            <div className="font-semibold font-[poppins] my-auto">
                                                <h2 className='text-xl '>ALKA MANOJ</h2>
                                                <h2 className='my-2 font-medium text-sm text-center'>Lead - Mangalore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p> 
                                            Alka Manoj, a fashion designer by profession, is our host for WNC meetings in Mangalore. When asked what drives her, she said, "I chose a creative path for my life and being a designer, creativity is what drives me forward."
                                            </p>
                                            <p className='py-6'> 
                                            Alka is a trained Bharatnatyam dancer and also takes Carnatic music classes for children when she isn't working. She is a multitasker and is always engaged in something creative. So it is no surprise when she came up with the idea of sustainable clothing for babies, as she saw a huge gap in the market for the same. She is currently working on a sustainable fashion babywear brand which focuses on natural dyes, natural materials, comfort and lots of love. The brand solves the worries of every new mother who wants just the best for their little one. Her brand will be launched in June.
                                            </p>                                        
                                        </div>
                                        </div>
                                    {/* co-host  */}
                                    <div className='max-w-lg mx-auto bg-white border-2 border-yellow mt-20 lg:mt-0' data-aos="fade-right" data-aos-duration="1000">
                                        <div className="flex justify-evenly">
                                            <img className='w-2/6 h-2/6 ml-2 rounded-sm -mt-12' src={hastha} alt='Hastha' />
                                            <div className="font-semibold font-[poppins] my-auto">
                                                <h2 className='text-xl uppercase'>Hastha Narayan</h2>
                                                <h2 className='my-2 font-medium text-sm text-center'>Co-Lead - Mangalore</h2>
                                            </div>
                                        </div>
                                        <div className='px-6 py-6 font-[raleway] text-fontgrey leading-tight tracking-wide text-md'>
                                            <p>Meet Hastha Narayan, our Co-Lead for Mangalore WNC chapter. A practicing Chartered Accountant, she is the Founder of Hastha & Co Chartered Accountants.</p>
                                            <p className="py-4">Apart from being a super successful CA, she is also a dancer, orator and badminton player rolled into one. She envisions a future where she is constantly growing in her profession and striking a beautiful balance between her business and passions. </p>
                                            <p className="py-4">When she is not busy working, she reads fiction, cooks, listens to music, dabbles in arts and crafts, or travels.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-1/6 text-center mb-24 lg:my-auto' data-aos="fade-left" data-aos-duration="1000">
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl lg:rotate-90'>MANGALORE</h1>
                                    {/* <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>M</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>A</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>N</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>G</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>A</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>L</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>O</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>R</h1>
                                    <h1 className='font-semibold italic text-purple text-4xl lg:text-5xl '>E</h1> */}
                                </div>
                            </div>

                           
                        </div>
                    </div>
            </section>
        </div>
    );
}

export default Card;