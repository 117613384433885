import React, { useEffect } from "react";
import { Switch } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { HiOutlineX } from "react-icons/hi";
import logoheader from "../src/images/logo-header.jpeg";
import { useState } from "react";
import Modal from "react-modal";
import ActiveStatus from "./Components/activeStatus";
import { getStatus } from "./Utils/functions";
import { validator } from "./Utils/validation";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const STYLES = {
  label: "text-sm font-semibold text-gray",
  value: "text-sm font-normal text-gary-400 capitalize ml-2",
};

export default function Dashboard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [date, setDate] = useState();
  const [amount, setAmount] = useState();
  const [errors, setErrors] = useState();
  const [drinkEnabled, setDrinkEnabled] = useState(false);
  const [cityEnabled, setCityEnabled] = useState(false);
  const [companionEnabled, setCompanionEnabled] = useState(false);
  const [formData, setFormData] = useState({
    date: null,
    isCity: null,
    isDrink: null,
    needCompanion: null,
    cocktailamount: null,
    mocktailamount: null,
    basicamount: null,
  });
  const [jsonData, setJsonData] = useState();
  const [city, setCity] = useState();
  // console.log(process.env.NODE_ENV);
  // const API_BASE_URL = process.env.NODE_ENV === 'development' ? "localhost:8080/api/v1" : '/';
  const API_BASE_URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/api/v1"
      : "https://womennetworkingclub.com/api/v1";
  const BASE_URL = "https://www.womennetworkingclub.com/";

  const OnEdit = (key) => {
    console.log("key",key)

    const selectedCity = jsonData[key].city
    console.log("selectedCity",selectedCity.date)
    setFormData((prevState) => ({
      ...prevState,
      date: jsonData[key].date,
      isCity: jsonData[key].isCity,
      isDrink: jsonData[key].isDrink,
      needCompanion: jsonData[key].needCompanion,
    }));
    setCity(selectedCity);
    setIsModalVisible(true);
  };

  const handleDate = (e) => {
    setDate(e);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [minDate, setMinDate] = useState(getCurrentDate());

  const formatDate = (eventDate) => {
    const d = new Date(eventDate).toDateString().split(" ");
    const formatedDate = d[2] + " " + d[1] + " " + d[3];
    return formatedDate;
  };

  const formatDrinkValue = (isDrink) => {
    if (isDrink) return "yes";
    return "no";
  };
  const handleAmountChange = (value, key) => {
    const val = value * 100;
    setAmount((prevState) => ({ ...prevState, [key]: val }));
  };
  const handleFormChange = (value, key) => {
    console.log("value", value, "key", key);
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const restoreValue = () => {
    setCityEnabled(false);
    setDrinkEnabled(false);
  };

  const getEvent = () => {
    fetch(`${BASE_URL}/getFirestoreEvent`)
      .then((res) => res.json())
      .then((response) => {
        console.log("response fron getFirestoreEvent ");
        console.log("response fron getFirestoreEvent ", response);
        setJsonData(response);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  const updateEvent = () => {
    const payload = {
      city: city,
      date: formData.date,
      isDrink: formData.isDrink,
      isCity: formData.isCity,
      needCompanion: formData.needCompanion,
      amount: amount,
    };
   

    const err = validator(payload);
    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }
    console.log("payload", payload);
    fetch(`${BASE_URL}/update`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("response",response)
        setIsModalVisible(false);

        restoreValue();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getEvent();
  }, []);

  console.log("json",jsonData)

  return (
    <>
      <div className="min-h-full">
        <div className="mx-auto flex h-16 md:h-36 px-4 sm:px-0 bg-whiteone shadow-lg ">
          <div className="flex flex-shrink-0 items-center space-x-4 md:ml-20 ">
            <img
              className="h-6 w-6  md:h-10 md:w-10"
              src={logoheader}
              alt="Your Company"
            />
            {/* </div> */}

            <h1 className="text-sm md:text-xl tracking-tight text-black ">
              DASHBOARD
            </h1>
          </div>
        </div>

        <div className=" w-full  px-3 py-6 lg:py-20 lg:px-20">
          <ul
            role="list"
            className="grid grid-cols-1 gap-x-6 gap-6 lg:grid-cols-3"
          >
            {jsonData &&
              Object.keys(jsonData).map((key, value) => (
                <li
                  className={classNames(
                    "relative rounded-xl border-2 p-3",
                    !jsonData[key].isCity && "border-yellow",
                    getStatus(jsonData[key].date)
                      ? " border-green"
                      : " border-yellow"
                  )}
                >
                  <div className="absolute -top-5 right-0 ">
                    <ActiveStatus
                      date={jsonData[key].date}
                      isCity={jsonData[key].isCity}
                    />
                  </div>

                  <div className="">
                    <div className="flex items-center gap-x-4 bg-gray-50 px-4 pt-3">
                      <div className="text-md uppercase font-semibold leading-6 text-gray-900">
                        {jsonData[key].city}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <dl>
                        <div className="flex  gap-x-4 py-2 px-4">
                          <div>
                            <dt className={STYLES.label}>
                              Event Date :
                              <span className={STYLES.value}>
                                <time>{formatDate(jsonData[key].date)}</time>
                              </span>
                            </dt>
                          </div>
                          <div>
                            <dt className={STYLES.label}>
                              Drink Availability :
                              <span className={STYLES.value}>
                                {formatDrinkValue(jsonData[key].isDrink)}
                              </span>
                            </dt>
                          </div>
                        </div>
                      </dl>
                      <div className="px-2">
                        <button
                          className="block px-4 py-0.5 text-xs font-semibold border border-box rounded leading-6 hover:border-slate"
                          onClick={() => OnEdit(key)}
                        >
                          Edit<span className="sr-only"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        <div className="">
          <Modal
            isOpen={isModalVisible}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="w-80">
              <div className="flex justify-end">
                <HiOutlineX
                  className="cursor-pointer"
                  onClick={() => setIsModalVisible(false)}
                />
              </div>
              <div className=" font-semibold text-center text-base text-purple uppercase mb-4 ">
              {city}
              </div>
              <div className="flex items-center justify-between mb-4 ">
                <h4 className="text-sm">
                  Date <span className="text-red">*</span>
                </h4>
                <div className="">
                  <input
                    value={formData.date}
                    min={minDate}
                    className="h-7 text-sm  "
                    type="date"
                    onChange={(e) => handleFormChange(e.target.value, "date")}
                  />
                  <p className="text-red text-xs ml-1">{errors?.date}</p>
                </div>
              </div>

              <div className="flex items-center  justify-between mb-4 ">
                <h4 className="text-sm">
                  Enable/Disable City<span className="ml-1 text-red">*</span>
                </h4>
                <Switch
                  name="is_city"
                  checked={formData.isCity}
                  onChange={(e) => handleFormChange(e, "isCity")}
                  className={`${
                    formData.isCity ? "bg-green" : "bg-slate"
                  } relative inline-flex h-5 w-10 items-center rounded-full`}
                >
                  <span
                    className={`${
                      formData.isCity ? "translate-x-5" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <div className="flex items-center  justify-between mb-4 ">
                <h4 className="text-sm">
                  Enable/Disable Companion
                  <span className="ml-1 text-red">*</span>
                </h4>
                <Switch
                  name="need_companion"
                  checked={formData.needCompanion}
                  onChange={(e) => handleFormChange(e, "needCompanion")}
                  className={`${
                    formData.needCompanion ? "bg-green" : "bg-slate"
                  } relative inline-flex h-5 w-10 items-center rounded-full`}
                >
                  <span
                    className={`${
                      formData.needCompanion ? "translate-x-5" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>

              <div className="flex items-center justify-between ">
                <h4 className="text-sm">
                  Enable/Disable IsDrink<span className="ml-1 text-red">*</span>
                </h4>
                <Switch
                  checked={formData.isDrink}
                  onChange={(e) => handleFormChange(e, "isDrink")}
                  className={`${
                    formData.isDrink ? "bg-green" : "bg-slate"
                  } relative inline-flex h-5 w-10 items-center rounded-full`}
                >
                  <span
                    className={`${
                      formData.isDrink ? "translate-x-5" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              {formData.isDrink === true ? (
                <>
                  <div className="grid grid-cols-1 gap-2  mt-2 ">
                    <div className="flex gap-2 items-center justify-between mt-4">
                      <h4 className="text-sm mr-1">
                        Amount for Cocktail
                        <span className="ml-1 text-red">*</span>
                      </h4>

                      <input
                        type="number"
                        className="border border-box text-sm rounded h-7 w-full  p-1"
                        placeholder="enter the amount"
                        onChange={(e) =>
                          handleAmountChange(e.target.value, "cocktail")
                        }
                      />
                    </div>
                    <p className="text-red ml-1 text-xs">{errors?.cocktail}</p>
                    <div className="flex gap-2 items-center justify-between mt-4">
                      <h4 className="text-sm mr-1">
                        Amount for Mocktail
                        <span className="ml-1 text-red">*</span>
                      </h4>
                      <input
                        type="number"
                        className="border border-box text-sm rounded h-7 w-full p-1"
                        placeholder="enter the amount"
                        name=""
                        onChange={(e) =>
                          handleAmountChange(e.target.value, "mocktail")
                        }
                      />
                    </div>
                    <p className="text-red text-xs ml-1">{errors?.mocktail}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-2 items-center justify-between mt-4 ">
                    <h4 className="text-sm ">Amount</h4>
                    <input
                      onChange={(e) =>
                        handleAmountChange(e.target.value, "basic")
                      }
                      type="number"
                      className="border border-box text-sm rounded h-7 w-full p-1"
                      placeholder="enter the amount"
                      name=""
                    />
                  </div>

                  <p className="text-red text-xs ml-1">{errors?.basic}</p>
                </>
              )}
            </div>

            <div className="flex items-end justify-end w-full mt-6 ">
              <button
                className="py-1 px-6 bg-purple text-white text-sm rounded   "
                onClick={updateEvent}
              >
                Submit
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
