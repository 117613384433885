import React, { useEffect, useRef, useState } from "react";
import Line2 from "../images/Line 2.png";
import guest from "../images/guest.png";
import location from "../images/location.png";
import { pastEvents } from "../Utils/constants";
import { clsx } from "clsx";
import { cbe1 } from "../Utils/image";
import Marquee from "react-fast-marquee";

export default function PastEvents() {
  const cities = [
    "COIMBATORE",
    "CHENNAI",
    "KOLKATA",
    "BANGALORE",
    "HYDERABAD",
    "DELHI",
    "MANGALORE",
  ];
  const [city, setcity] = useState("COIMBATORE");
  const marqueeRef = useRef(null);

  function handleChange(e) {
    const value = e.target.value;
    console.log("value", pastEvents[value]);
    const data = pastEvents[value];
    console.log("", data);
    setcity(value);
  }



  return (
    <div class="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex justify-between">
        <div className="border-l-4 border-purple pl-4 font-[Poppins] mb-6 sm:mt-10">
          <h1 className="uppercase text-lg sm:text-3xl md:text-xl lg:text-2xl">
            OUR PAST EVENTS
          </h1>
          <h4 className="text-sm  text-fontgrey sm:text-md mt-1">
            Lorem ipsum dolor sit amet consectetur.cras risus vestic
          </h4>
        </div>
        <div className="flex justify-center  sm:content-center m-10 ">
          <select
            className="w-[50%] sm:w-full uppercase bg-purple text-white text-sm font-semibold rounded p-2 focus:outline-none lg:text-base"
            onChange={handleChange}
          >
            <option selected disabled>
              Select City
            </option>
            {cities.map((cities, index) => (
              <option value={cities}>{cities}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <div className="sm:flex sm:justify-between mx-auto sm:max-w-7xl mt-10">
          <div className="mt-10">
            <h1
              className="uppercase
                   font-bold text-purple text-xl lg:text-3xl mb-5"
            >
              {city}
            </h1>

            <div>
              <h1 className="sm:text-[18px] text-fontgrey font-light sm:text-[15px] ">
                Hosted Locations and our WNC Guest and our Event Photos
              </h1>
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center gap-4 py-6  mb-10">
              <div className="flex  items-center px-2 ml-2">
                <img
                  className="w-16 rounded-full "
                  src={pastEvents[city].lead.image}
                  alt=""
                />
                <div className="flex flex-col ml-2  ">
                  <h4 className="font-semibold text-[14px] sm:text-lg">
                    {pastEvents[city].lead.name}
                  </h4>
                  <h6 className="text-fontgrey text-[12px] sm:text-sm">
                    {pastEvents[city].lead.position}
                  </h6>
                </div>
              </div>

              {pastEvents[city].co_lead.name !== "" && (
                <img
                  className="hidden sm:block  sm:h-20 sm:w-0.5"
                  src={Line2}
                  alt=""
                />
              )}

              <div className="flex items-center px-4">
                <img
                  className="w-16 rounded-full "
                  src={pastEvents[city].co_lead.image}
                  alt=""
                />
                <div className="flex flex-col ml-2  ">
                  <h4 className="font-semibold text-[14px] sm:text-lg">
                    {pastEvents[city].co_lead.name}
                  </h4>
                  <h6 className="text-fontgrey text-[12px] sm:text-sm">
                    {pastEvents[city].co_lead.position}
                  </h6>
                </div>
              </div>
              {/* 
              <div className="flex flex-row items-center border-2">
                <img
                  className="w-16 rounded-full "
                  src={pastEvents[city].co_lead.image}
                  alt=""
                />

                <div className="flex flex-col ml-2  ">
                  <h4 className="font-semibold text-[14px] sm:text-lg">
                    {pastEvents[city].co_lead.name}
                  </h4>



                  <h6 className="font-light text-[12px] sm:text-sm">
                    {pastEvents[city].co_lead.position}
                  </h6>
                </div>
              </div> */}
            </div>
          </div>
          {pastEvents[city].images && (
            <div className="flex justify-center">
              <img
                className="rounded-lg w-full h-full sm:w-[350px] sm:h-[300px] sm:mr-10 mb-2"
                src={pastEvents[city].images}
              />
            </div>
          )}
        </div>

        {/* {pastEvents[city].location.map((items) => (
              <img src={items} className="w-[100px] h-[80px]  sm:w-[130px] sm:h-[130px]" />
            ))}
     */}
        <div className="mt-10  mx-auto max-w-5xl">
          <Marquee>
            <div className="m-4 sm:mt-10 relative flex overflow-x-hidden  ">
              <div class="flex space-x-10 py-12  ">
                {pastEvents[city].location.map((items) => (
                  <img
                    src={items}
                    className="w-[90px] h-[90px]  sm:w-[130px] sm:h-[130px]"
                  />
                ))}
              </div>
              <div ref={marqueeRef} class="flex space-x-10 py-12 ">
                {pastEvents[city].location.map((items) => (
                  <img
                    src={items}
                    className="w-[90px] h-[90px]  sm:w-[130px] sm:h-[130px] ml-8"
                  />
                ))}
              </div>

              {/* <div class=" flex space-x-10 absolute top-0 py-12  animate-marquee2 ml-6">
            {pastEvents[city].location.map((items) => (
            <img src={items} className="w-[100px] h-[80px]  sm:w-[120px] sm:h-[120px]" />
            ))}
          </div> */}
            </div>
          </Marquee>
        </div>

        <div className="sm:mt-10">
          {/* <div className="jutify-center shadow border-l-4 border-yellow h-[415px]  px-8 py-6 mx-6  mt-16 text-sm md:w-[400px]"> */}
          <h2 className="font-semibold text-center  text-purple text-[28px] md:text-[30px]  mb-[35px]">
            OUR EVENT GUESTS
          </h2>

          <div className="sm:flex flex-wrap px-5 mx-auto max-w-7xl mb-20">
            {pastEvents[city].guest.map((items, key) => (
              <div
                className={clsx({
                  " flex flex-row mx-auto w-[293px]   sm:w-[320px] h-[89px]  items-center rounded-lg m-2 my-6 p-2 ": true,
                  " bg-gradient-to-tr from-purple to-lightpurple":
                    key % 2 === 0,
                  " bg-gradient-to-tr from-yellow to-lightyellow":
                    key % 2 !== 0,
                })}
              >
                <img className="w-16 rounded-full border" src={guest} alt="" />

                <div className="flex flex-col ml-4 ">
                  <h1
                    className={clsx({
                      "text-[16px]  font-semibold uppercase mb-1 sm:text-lg": true,
                      "text-white": key % 2 === 0,
                      "text-black": key % 2 !== 0,
                    })}
                  >
                    {pastEvents[city].guest[key].name}
                  </h1>
                  {/* <h1 className="font-light text-white text-sm"> */}

                  <h1
                    className={clsx({
                      "font-light text-[14px] md:text[15px]": true,
                      "text-black": key % 2 !== 0,
                      "text-white": key % 2 === 0,
                    })}
                  >
                    {pastEvents[city].guest[key].discription}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

{
  /* <section>
              <div className="px-1 font-[poppins] mt-14 mx-4 md:mx-10 lg:mx-10 mb-10">
                <div className="md:flex max-w-7xl mx-auto"> */
}
{
  /* <div className=" shadow border-l-4 border-yellow  h-3/4 px-8 py-6 mx-6 md:w-2/4 ">
                    <h2 className="font-semibold text-purple lg:text-xl">
                      LOCATIONS
                    </h2>

                    <ul className="font-semibold text-sm md:text-sm mt-2 lg:text-sm lg:leading-6">
                      <li className="py-2 lg:py-1 ">
                        {pastEvents[item].location.map((items) => (
                          <div className="flex">
                            <img
                              className="w-4 h-4 mr-2 mt-3 md:my-auto"
                              src={location}
                              alt="location"
                            />
                            <h1 className=" p-2 text-[16px] text-fontgrey">{items} </h1>
                          </div>
                        ))}
                      </li>
                    </ul>
                  </div> */
}

{
  /* <div className="jutify-center shadow border-l-4 border-yellow h-[415px]  px-8 py-6 mx-6  mt-16 text-sm md:w-[400px]">
                    <h2 className="font-semibold text-center  text-purple lg:text-xl m-4">
                      OUR EVENT GUESTS
                    </h2>

                    {/* <div className="flex flex-row mx-auto  w-[293px] h-[89px] justify-center items-center rounded-lg bg-gradient-to-r from-purple to-lightpurple m-2 p-2 "> */
}

{
  /* {pastEvents[item].guest.map((items, key) => (
                      <div
                        className={clsx({
                          " flex flex-row mx-auto   sm:w-[300px] sm:h-[89px]  items-center rounded-lg m-2 my-4 p-2 ": true,
                          " bg-gradient-to-tr from-purple to-lightpurple":
                            key % 2 === 0,
                          " bg-gradient-to-tr from-yellow to-lightyellow ":
                            key % 2 !== 0,
                        })}
                      >
                        <img
                          className="w-16 rounded-full border"
                          src={guest}
                          alt=""
                        />

                        <div className="flex flex-col ml-4 ">
                          <h1
                            className={clsx({
                              "text-sm sm:text-lg": true,
                              "text-white": key % 2 === 0,
                              "text-black": key % 2 !== 0,
                            })}
                          >
                            {pastEvents[item].guest[key].name}
                          </h1>
                          {/* <h1 className="font-light text-white text-sm"> */
}

//         <h1
//           className={clsx({
//             "font-light text-sm md:text[15px]": true,
//             "text-black": key % 2 !== 0,
//             "text-white": key % 2 === 0,
//           })}
//         >
//           {pastEvents[item].guest[key].discription}
//         </h1>
//       </div>
//     </div>
//   ))}
// </div> */
// }
{
  /* </div>
              </div>
            </section> */
}

{
  /* <div className="flex  ">
    <div>
      <img className=" h-12 w-0.5 " src={Line2} alt="" />
    </div>
    <div className="px-4">
      <h1 className="uppercase text-xl lg:text-3xl">OUR PAST EVENTS</h1>
      <h1 className="text-base">
        Lorem ipsum dolor sit amet consectetur.cras risus vestic
      </h1>
    </div>
  </div> */
}
{
  /* <div
    className="space-x-8 mt-7 
  "
  > */
}
{
  /* <select className="bg-purple text-white text-sm font-semibold py-2 px-6  rounded focus:outline-none lg:text-base">
      <option>YEAR</option>
      {years.map((year, index) => (
        <option key={index}>{year}</option>
      ))}
    </select>
    <select className="bg-purple text-white text-sm font-semibold py-2 px-2 rounded  focus:outline-none lg:text-base">
      <option>MONTH</option>

      {months.map((month, index) => (
        <option key={index}>{month}</option>
      ))}
    </select> */
}
{
  /* </div> */
}
{
  /* <div className="mx-auto max-w-5xl  grid grid-cols-3 gap-4 mt-10">
  <img className=" h-90 w-70 " alt="" />
  <div className="space-y-4">
    <img className=" h-40 " alt="" />
    <img className=" h-40" alt="" />
  </div>
  <img className="h-90 w-70 " alt="" />
 </div> */
}

{
  /* 
  <div className="flex my-10 ">
    <img className="h-8" src={Location} />
    <h1 className="font-semibold text-md mt-1">
      ZONE CONNECT LAKSHIMI MILLS - COIMBATORE
    </h1>
  </div> */
}

{
  /* {pastEvents[item].images.map((items) => (
          <img src={images} alt="image"/>
        ))} */
}
