export function isCityDisabled(eventDate) {
  if (processDate(eventDate) < processDate(getCurrentDate())) {
    return true;
  } else if (processDate(eventDate) <= processDate(getCurrentDate())) {
    return false;
  }
}

function processDate(date) {
  var parts = date.split("/");
  var date = new Date(parts[1] + "/" + parts[0] + "/" + parts[2]);
  return date.getTime();
}

function getCurrentDate() {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = currentDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export function getStatus(date) {
  // console.log("date", date);
  const today = new Date().toISOString().slice(0, 10);
  const currentdate = new Date(today);
  const eventdate = new Date(date);
  // console.log({ currentdate, eventdate });

  if (currentdate < eventdate) {
    return true;
    // console.log("currentdate is earlier than eventdate");
  } else if (currentdate >= eventdate && currentdate === eventdate) {
    return false;
    // console.log("currentdate is later than eventdate");
  }
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const daySuffix = getDaySuffix(date.getDate());
  return formattedDate.replace(/\b(\d{1,2})\b/g, `$1${daySuffix}`);
}
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

const inputDate = "2024-05-01";
const formattedDate = formatDate(inputDate);
