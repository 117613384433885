import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Link, redirect } from "react-router-dom";

export default function Verify() {
  const [code, setAccessCode] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const ACCESS_CODE = "wnc@admin123";
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-lg">
        <h1 className="text-2xl font-semibold text-center text-blacl ">
          LOG IN
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              for="password"
              className="block text-md font-semibold text-black"
            >
              Access-code
            </label>
            <div className="flex">
              <input
                type={type}
                value={code}
                autoComplete="current-password"
                className="block w-full px-4 py-2 mt-2 text-black bg-white border-2 border-yellow rounded-md "
                onChange={(val) => {
                  setAccessCode(val.target.value);
                }}
              />
              <span
                className="flex items-center justify-around"
                onClick={handleToggle}
              >
                <Icon className=" absolute mr-10 " icon={icon} size={18} />
              </span>
            </div>
          </div>
    
          {code === ACCESS_CODE ? (
            <div className="mt-6">
              <Link to="/dashboard">
                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple rounded-md hover:bg-blue-300 ">
                  Verify
                </button>
              </Link>
            </div>
          ) : (
            <div className="mt-6">
              <Link to="/dashboard">
                <button
                  disabled
                  className="w-full px-4 py-2 tracking-wide text-black transition-colors duration-200 transform bg-box rounded-md hover:bg-blue-300 "
                >
                  Verify
                </button>
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
