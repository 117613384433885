import { React } from "react";
import ScrollTop from "../Components/Scrollup";
import Form from "../Components/Form";
import Testimonial from "../Components/Testimonial";
import register_image from "../images/register.svg";
import Layout from "../Components/layout";
import Registration from "../Components/Registration";

function Register() {
  console.log(Date.now);
  return (
    <Layout>
      <div>
        {/* form section */}

        <section className="bg-grey pb-10 h-max w-full font-[poppins]">
          <div className="md:px-8 lg:mx-0 ">
            <div className="flex-none sm:flex pt-20 max-w-6xl mx-auto ">
              <div className="bg-yellow  px-10 pt-32 text-xl w-2/6 hidden lg:block">
                <p className="font-thin ">
                  India's <span className="font-bold">first</span> and{" "}
                  <span className="font-bold">only</span>
                  <br />
                  non-membership based all{" "}
                  <span className="text-white">women networking club!</span>
                </p>
                <img className="mt-10" src={register_image} alt="register" />
              </div>

              <Form />
              {/* <Registration /> */}
            </div>
          </div>

          {/* Chat Community Section */}
          {/* <section>
                <div className='bg-grey py-10 px-10 '>
                    <div className='bg-white max-w-6xl mx-auto px-8 lg:px-10 mt-2 py-6 rounded-lg '>
                        <h3 className='pt-4 text-center font-semibold text-xl pt-12 px-12 sm:px-0'>Join our Telegram Community</h3>
                        <hr className='border-t-2 border-purple w-14 mt-2 mx-auto' />
                        <div className='flex justify-evenly flex-wrap pt-10 gap-4 md:gap-4'>
                            <div className=' w-28 h-36 text-center '>
                                <img className='w-20 h-20 mx-auto' src='Images/cbe.png' alt='coimbatore logo'/>
                                <a href='https://t.me/+JaUMF5ywOKAzZjY9' target="_blank" rel="noreferrer" >
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Coimbatore</p></a>
                            </div>

                            <div className=' w-28 h-36 text-center '>
                                <img className='h-20 mx-auto' src='Images/chennai.png' alt='chennai logo' />
                                <a href='https://t.me/+TwWYmnmbJophNzFl' target="blank">
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Chennai</p></a>
                            </div>
                            <div className=' w-28 h-36 text-center '>
                                <img className='' src='Images/kolkata.png' alt='kolkata logo' />
                                <a href='https://t.me/+N6zQoU3PKwNlNGM1' target="blank">
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Kolkata</p></a>
                            </div>
                            <div className=' w-28 h-36 text-center '>
                                <img className='w-20 h-20 mx-auto'  src='Images/hyd.png'  alt='hyderbad logo'/>
                                <a href='https://t.me/+hKjmRTsGdDRhOWY1' target="blank">
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Hyderbad</p></a>
                            </div>
                            <div className=' w-28 h-36 text-center '>
                                <img  src='Images/banglore.png' alt='banglore logo'/>
                                <a href='https://t.me/+rdC3JeGg_PhlNWM1' target="blank">
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Banglore</p></a>
                            </div>
                            <div className=' w-28 h-36 text-center '> 
                                <img className='h-20 w-28'  src='Images/mangalore.png' alt='mangalore logo'/>
                                <a href='https://t.me/+rdC3JeGg_PhlNWM1' target="blank" rel="noreferrer">
                                <p className='text-blue mt-4 text-sm font-semibold hover:underline'>Mangalore</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </section>

        <ScrollTop />

        <Testimonial />

        {/* <TestiCard /> */}
      </div>
    </Layout>
  );
}

export default Register;
