import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Autoplay,} from 'swiper/modules'; 
import 'swiper/css';
import 'swiper/css/bundle'
import female_avatar from '../images/female-avatar.png';
import quote_icon from '../images/quote-icon.png';






function Testimonial (){



    const Testimonials = [
        {
            name: "Shanti",
            city: "Chennai",
            content:"The event was really nice. I liked that it's just an hour and everyone gets to talk about themselves. Great to socialize and help other women!",
        },
        {
            name:"Shobana",
            city: "Coimbatore",
            content: "The guest of this meeting Ms. Reshmi was an inspirational woman. She had shared a lot of her inspiring stories on how to adapt to a change. At the end of her session, I'm very confident.",
        },
        // {
        //     name: "Ashwini",
        //     city: "Chennai",
        //     content: "This was a totally new experience for me and I enjoyed it. I had a great time with a wonderful group of women who are so creative and talented. This meeting also helped me in getting to know a lot of people and what they do in their day to day lives. Meetings like these are really helpful to form new connections and also share ideas with each other.",
        // },
        {
            name:"Shreya",
            city: "Mangalore",
            content: "It was very nice to meet so many women who are in different profession. It is a very good platform where we can talk about our business and the overall experience was simply awesome!!",
        },
        {
            name: "Maadhuryaa",
            city: "Bangalore",
            content: "I really loved the activity. It was much needed to me at the moment because of my current health issues. It taught me how important it is to take care of the body and the mind. The activity was refreshing and fun.",   
        },
        {
            name: "Nikita",
            city:"Kolkata",
            content: "I had a great time. It was a real refreshing experience to come together with women who share the same journey and mentality of 'go big or go home'.",
        },
        {
            name: "Prasanthi",
            city: "Hyderabad",
            content: "The event is a really good initiative for women from different fields to collaborate with each other and network. Really liked the informal approach to the event wherein it was more like a dialogue.",
        },
        {
            name: "Richa",
            city: "Delhi",
            content: "It's a good initiative liked the way you are bringing together strong women and helping them to grow by supporting each other.",
        },
    ]

    return (
        <>
            <div className="bg-lightsandal py-10 px-4 sm:px-14 ">

                    <div className="border-l-4 border-purple pl-4 font-[Poppins]">
                        <h1 className='uppercase text-xl sm:text-3xl font-semibold  lg:text-2xl'>What our guests had to say</h1>
                        <h4 className="text-xm sm:text-md mt-1">We have many reasons to prove why our guests love us</h4>
                    </div>
                    
                    <div className="flex flex-row justify-between items-center ">
                        {/* <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div> */}
                        <Swiper 
                            spaceBetween={10}
                            slidesPerView={3}
                            modules = {[Navigation, Pagination, Autoplay]}
                            autoplay = {500}
                            pagination = {{clickable : true}}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1, 
                                    spaceBetween: 10,
                                },
                                720: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1200:{
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                }


                            }}
                        >
                            {Testimonials.map((object) => {
                                return (
                                    <SwiperSlide>
                                        <div className="shadow-lg px-4 py-7 testi-card my-16 h-60 rounded-xl relative bg-white font-[Poppins]">
                                            <img className='w-6 mb-2' src={quote_icon} alt='' />
                                            <p className="">{object.content}</p>

                                                <div className="flex flex-row items-center absolute bottom-1 left-[35%] ">
                                                    <img className="w-16" src={female_avatar} alt="" />

                                                    <div className="flex flex-col ml-2 ">
                                                        <h4 className='font-semibold text-lg'>{object.name}</h4>
                                                        <h6 className='font-light text-sm'>{object.city}</h6>
                                                    </div>

                                                </div>
                                            </div>
                                    
                                    </SwiperSlide>
                                  
                                   
                                )
                            })}
                        </Swiper>
                        
                </div>
            </div>
        </>
    )
}

export default Testimonial