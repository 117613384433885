import React from "react";
const notificationMethods = [
  { id: "cocktail", title: "Cocktail" },
  { id: "mocktail", title: "Mocktail" },
];
function DrinkSelector({ setregisterDetails, registerDetails, error }) {
  return (
    <div className="my-4">
      <h4 className="text-sm">
        Kind of drink<span className="text-red">*</span>
      </h4>
      <fieldset className="mt-1">
        <legend className="sr-only">kind of drink</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {notificationMethods.map((itm) => (
            <div key={itm.id} className="flex items-center">
              <input
                id={itm.id}
                name="kind-of-drink"
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={() =>
                  setregisterDetails({ ...registerDetails, drink: itm?.id })
                }
              />

              <label
                htmlFor={itm.id}
                className="ml-3 block text-sm leading-6 text-gray-900"
              >
                {itm.title}
              </label>
            </div>
          ))}
        </div>
        <p className="text-red ml-1 text-sm">{error.drink}</p>
      </fieldset>
    </div>
  );
}

export default DrinkSelector;
